<template>
  <div>
    <!-- <home-login></home-login> -->
    <!-- <home-popular-deal></home-popular-deal> -->
  </div>
</template>
<style>
  .first-loading {
    background-image: url("../assets/images/icons/loader-bike.gif");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
  }
  .first-loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 999999999;
  }
</style>
<script>

export default {
  data () {
    return {
    }
  },
  mounted () {
    var vm = this
    vm.$root.$on('onTrackingHomepage', (data) => {
      let layer = {
        screen: {
          'name': 'homepage.home',
          'type': 'homepage'
        },
        'modules': data.modules || [],
        'assets': data.assets || {}

      }
      vm.eventTrackingModuleLoad(layer)
      vm.eventTrackingScreenLoad(vm, layer)
    })
  },
  destroyed () {
    this.$root.$off('onTrackingHomepage')
  },
  created () {
  }
}
</script>
